import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { GiComb } from 'react-icons/gi'
import { HiScissors } from 'react-icons/hi'
import { GiRazor } from 'react-icons/gi'


function Values() {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: <GiComb fontSize={70} className='titleColorFt' />,
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: <HiScissors fontSize={70} className='titleColorFt' />,
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: <GiRazor fontSize={70} className='titleColorFt' />,
    },
  ]

  const fondo = 'https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/haircut-font.avif?alt=media&token=b758eb10-64ef-4ac6-86e7-305a85906d03'
  return (
    <>
      <div className="container mx-auto md:w-full py-10 flex flex-col justify-center items-center bg-cover"
            style={{ backgroundImage: `url("${fondo}")`}}
      >
        <div className='flex flex-wrap justify-center'>
          {
          valuesData.slice(0,2).map((items, index) => {
            return (
              <div key={index} className="bg-white bg-opacity-70 backdrop-blur-sm drop-shadow-md w-[340px] md:w-[500px] min-h-[380px] md:text-start text-center m-4 p-5 shadow-lg rounded transform hover:scale-105 duration-300 ease-in-out">

                <div className="flex justify-center">
                  {items.icon}
                </div>
                <div className="p-4 ">
                  <h2 className="text-2xl text-center uppercase">{items.title}</h2>
                  <p className='text-center pb-3'>
                    {items.description}
                  </p>
                </div>
              </div>
            )
          })
        }
        </div>


{
          valuesData.slice(2).map((items, index) => {
            return (
              <div key={index} className="bg-white w-[340px] md:w-[500px] lg:w-[70%]  bg-opacity-70 backdrop-blur-sm drop-shadow-md md:text-start text-center m-4 p-5 shadow-lg rounded transform hover:scale-105 duration-300 ease-in-out">
                <div className="flex justify-center">
                  {items.icon}
                </div>
                <div className="p-4 ">
                  <h2 className="text-2xl text-center uppercase">{items.title}</h2>
                  <p className='text-center pb-3'>
                    {items.description}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
}

export default Values;
